<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
            <v-flex sm12 md12 lg8 xl8 >
			<div style="    text-align: center;">
				<!-- <h1>hiiii</h1> -->
				<!-- {{rows}} -->
                <h1 class="my-3 text-center">
                    Items by Months
                </h1>
				<div id="chart" align-center>
					<apexchart type="bar"  :options="chartOptions" :series="series"></apexchart>
				</div>
			</div>
</v-flex>
		</v-container>
	</div>
</template>
<script>
	import VueApexCharts from 'vue-apexcharts'
	import requests from '../../requests/reports.requests'
	export default {
		data() {
			return {
				rows: [],

				loading: false,
				series: [{
					name: [],
					data: []
				}],
				chartOptions: {
					chart: {
						height: 350,
						type: 'bar',
					},
					plotOptions: {
						bar: {
							borderRadius: 10,
							dataLabels: {
								position: 'top', // top, center, bottom
							},
						}
					},
					dataLabels: {
						enabled: true,
						formatter: function (val) {
							return val ;
						},
						offsetY: -20,
						style: {
							fontSize: '12px',
							colors: ["#304758"]
						}
					},

					xaxis: {
						categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
						position: 'top',
						axisBorder: {
							show: false
						},
						axisTicks: {
							show: false
						},
						crosshairs: {
							fill: {
								type: 'gradient',
								gradient: {
									colorFrom: '#D8E3F0',
									colorTo: '#BED1E6',
									stops: [0, 100],
									opacityFrom: 0.4,
									opacityTo: 0.5,
								}
							}
						},
						tooltip: {
							enabled: true,
						}
					},
					yaxis: {
						axisBorder: {
							show: false
						},
						axisTicks: {
							show: false,
						},
						labels: {
							show: false,
							formatter: function (val) {
								return val ;
							}
						}

					},
					// title: {
					// 	text: 'Monthly Chart',
					// 	floating: true,
					// 	// offsetY center title
                    //     offsetY: 330,
                    //     // align in the bottom right corner
                    //     align: 'center',
					// 	style: {
                    //         color: '#444'
					// 	}
					// }
				},






			}
		},
		components: {
			apexchart: VueApexCharts,

		},
		mounted() {
			this.openReport()
		},
		methods: {
			openReport() {
				this.loading = true
				requests.itemByMonthReport().then(res => {
					this.rows = res.data.rows
					      // Prepare data for the chart
      const categories = [];
      const orderStatuses = {};

      // Iterate over the data and populate the arrays
      for (const row of this.rows) {
        const { month, order_item_status, count } = row;

        // Check if the month exists in the categories array
        const monthIndex = categories.indexOf(month);
        if (monthIndex === -1) {
          // If the month doesn't exist, add it to the categories array
          categories.push(month);
        }

        // Check if the order_item_status exists in the orderStatuses object
        if (!orderStatuses[order_item_status]) {
          // If it doesn't exist, create an empty array for it
          orderStatuses[order_item_status] = [];
        }

        // Find the corresponding count value for the month
        const existingData = orderStatuses[order_item_status].find(data => data.month === month);

        if (existingData) {
          // If the count value exists for the month, update it
          existingData.count += count;
        } else {
          // If the count value doesn't exist for the month, create a new entry
          orderStatuses[order_item_status].push({ month, count });
        }
      }

      // Create the series data based on the orderStatuses object
      const seriesData = Object.entries(orderStatuses).map(([status, data]) => {
        const dataPoints = categories.map(month => {
          const entry = data.find(item => item.month === month);
          return entry ? entry.count : 0;
        });

        return {
          name: status,
          data: dataPoints
        };
      });

      // Update the series and xaxis in chartOptions
      this.series = seriesData;
      this.chartOptions.xaxis.categories = categories;

					this.loading = false
				})
			}
		},
	}
</script>
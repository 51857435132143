<template>
	<div>
        <h1 class="text-center my-6">Welcome to Elly Online Store</h1>
        <h1 class="text-center my-1">Dollar Price : {{store_information[0].dollar_price}}</h1>
    <moneychrt v-if="user.user_role==1"></moneychrt>
	<itemchart></itemchart>
    <!-- <ordercitychart></ordercitychart> -->
	</div>
</template>
<script>
import ItemsChartByMonthVue from './charts/ItemsChartByMonth.vue';
import MoneyChartByMonthVue from './charts/MoneyChartByMonth.vue';
// import OrderChartByCityVue from './charts/OrderChartByCity.vue';

	export default {
		data() {
			return {
				

			}
		},
		components: {
			itemchart :ItemsChartByMonthVue,
            moneychrt:MoneyChartByMonthVue,
            // ordercitychart :OrderChartByCityVue

		},
        computed: {
           user(){
               return this.$store.getters.user
           },
           store_information(){
               return this.$store.getters.store_information_list
           }
        },
		
	}
</script>
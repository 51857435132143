<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2 ">
			<v-layout row wrap justify-center>
				<v-flex sm12 md12 lg8 xl8>
					<div style="text-align: center;" class="container-with-border">
						<!-- <h1>hiiii</h1> -->
						<!-- {{rows}} -->
						<h3 class="my-3 text-center">
							Chart of Money by Months
						</h3>
						<!-- create two date picker for to and from  -->
						<v-divider class="py-3"></v-divider>
						<v-layout row wrap justify-center>
							<v-flex xs10 lg3 xl3 md4>
								<v-text-field label="From" type="date" dense outlined class="mx-1" v-model="from"></v-text-field>
							</v-flex>
							<v-flex xs10 lg3 xl3 md4>
								<v-text-field label="To" type="date" dense outlined class="mx-1" v-model="to"></v-text-field>
							</v-flex>
							<v-flex xs10 lg3 xl3 md4>

								<v-btn color="primary" @click="openReport">Get</v-btn>
							</v-flex>
						</v-layout>
						<div id="chart" align-center>
							<apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
						</div>
					</div>
				</v-flex>
				<v-flex sm12 md12 lg3 xl3>
					<div class="ma-3">
						<h3 class="my-3 text-center">
							Total
						</h3>
						<v-divider class="pa-2"></v-divider>

						<p>Total Delivered : ${{(total_delivered_price|| 0).toLocaleString()}}</p>
						<v-divider class="pa-2"></v-divider>
						<p>Total Pending : ${{(total_pending_price|| 0).toLocaleString()}}</p>
						<v-divider class="pa-2"></v-divider>
						<p>Total Soldout : ${{(total_soldout_price|| 0).toLocaleString()}}</p>
						<v-divider class="pa-2"></v-divider>
						<p>Total Purchased : ${{(total_purchased_price|| 0).toLocaleString()}}</p>

					</div>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>
<script>
	import VueApexCharts from 'vue-apexcharts'
	import requests from '../../requests/reports.requests'
	export default {
		data() {
			return {
				rows: [],
				totalRows: [],
				total_pending_price: 0,
				total_delivered_price: 0,
				total_soldout_price: 0,
				total_purchased_price: 0,


				// from = two months ago
				from: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString(),
				to: new Date().toISOString(),
				loading: false,
				series: [{
					name: [],
					data: []
				}],
				chartOptions: {
					chart: {
						height: 350,
						type: 'bar',
					},
					plotOptions: {
						bar: {
							borderRadius: 10,
							dataLabels: {
								position: 'top', // top, center, bottom
							},
						}
					},
					dataLabels: {
						enabled: true,
						formatter: function (val) {
							return "$" + (val|| 0).toLocaleString();
						},
						offsetY: -20,
						style: {
							fontSize: '12px',
							colors: ["#304758"]
						}
					},

					xaxis: {
						categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
						position: 'top',
						axisBorder: {
							show: false
						},
						axisTicks: {
							show: false
						},
						crosshairs: {
							fill: {
								type: 'gradient',
								gradient: {
									colorFrom: '#D8E3F0',
									colorTo: '#BED1E6',
									stops: [0, 100],
									opacityFrom: 0.4,
									opacityTo: 0.5,
								}
							}
						},
						tooltip: {
							enabled: true,
						}
					},
					yaxis: {
						axisBorder: {
							show: false
						},
						axisTicks: {
							show: false,
						},
						labels: {
							show: false,
							formatter: function (val) {
								return "$" + val;
							}
						}

					},
					// title: {
					// 	text: 'Monthly Chart',
					// 	floating: true,
					// 	// offsetY center title
					//     offsetY: 330,
					//     // align in the bottom right corner
					//     align: 'center',
					// 	style: {
					//         color: '#444'
					// 	}
					// }
				},






			}
		},
		components: {
			apexchart: VueApexCharts,

		},
		mounted() {
			this.openReport()
		},
		methods: {
			openReport() {
				this.loading = true
				console.log(this.from)
				console.log(this.to)
				let query = {
					from: this.from,
					to: this.to
				}
				this.total_pending_price = 0;
				this.total_delivered_price = 0;
				this.total_soldout_price = 0;
				this.total_purchased_price = 0;
				console.log("this.rows")
				requests.moneyByMonthReport(query).then(res => {
					this.totalRows = res.data.total


					for (let i = 0; i < res.data.total.length; i++) {
						if (res.data.total[i].order_item_status == "pending") {
							this.total_pending_price = res.data.total[i].total
						}
						if (res.data.total[i].order_item_status == "delivered") {
							this.total_delivered_price = res.data.total[i].total
						}
						if (res.data.total[i].order_item_status == "soldout") {
							this.total_soldout_price = res.data.total[i].total
						}
						if (res.data.total[i].order_item_status == "purchased") {
							this.total_purchased_price = res.data.total[i].total
						}
					}

					this.rows = res.data.rows
					// Prepare data for the chart
					const categories = [];
					const orderStatuses = {};

					// Iterate over the data and populate the arrays
					for (const row of this.rows) {
						const { month, order_item_status, total } = row;

						// Check if the month exists in the categories array
						const monthIndex = categories.indexOf(month);
						if (monthIndex === -1) {
							// If the month doesn't exist, add it to the categories array
							categories.push(month);
						}

						// Check if the order_item_status exists in the orderStatuses object
						if (!orderStatuses[order_item_status]) {
							// If it doesn't exist, create an empty array for it
							orderStatuses[order_item_status] = [];
						}

						// Find the corresponding total value for the month
						const existingData = orderStatuses[order_item_status].find(data => data.month === month);

						if (existingData) {
							// If the total value exists for the month, update it
							existingData.total += total;
						} else {
							// If the total value doesn't exist for the month, create a new entry
							orderStatuses[order_item_status].push({ month, total });
						}
					}

					// Create the series data based on the orderStatuses object
					const seriesData = Object.entries(orderStatuses).map(([status, data]) => {
						const dataPoints = categories.map(month => {
							const entry = data.find(item => item.month === month);
							return entry ? entry.total : 0;
						});

						return {
							name: status,
							data: dataPoints
						};
					});

					// Update the series and xaxis in chartOptions
					this.series = seriesData;
					this.chartOptions.xaxis.categories = categories;

					this.loading = false
				})
			}
		},
	}
</script>
<style scoped>
	.container-with-border {
		border: 1px solid #ccc; /* Add your desired border style */
	}
</style>